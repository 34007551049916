/**
 * User entity store.
 *
 */
import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '@/store/entity';
import store from '@/store';
import { GroupModel } from '@/interfaces/models/group.interface';
import GroupsModule from './index';

import {
  readSecurityGroup,
  createSecurityGroup,
  updateSecurityGroup,
  getPermissionsList,
  setPermissions,
  getSecurityGroupsListAll,
} from '@/api/users';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';
import { SelectList } from '@/lib/formFactory/select.interface';
import { BinaryBoolean } from '@/interfaces/shared';

export interface GroupEntityInterface {
  id?: string;
  name: string;
  isSupervisor: BinaryBoolean;
  permissions: string[];
}

export const MODULE_NAME = 'settingsGroup';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class GroupEntityModule extends EntityBaseModule {
  explodePermission = RegExp(/^CAN_(?<operation>[A-Z]+)_(?<subject>[A-Z]+(?:_[A-Z]+)*)$/);
  titleEdit = '';
  model: GroupModel;
  permissionsList: Record<string, { value: boolean; title: string }> = {};
  groupedPermissionsList: Record<string, string[]> = {};
  singlePermissionsList: string[] = [];
  securityGroupsList: SelectList = [];
  isEdit = false;
  usedPermissions = [
    'CAN_READ_CLIENT',
    'CAN_READ_CLIENT_MARKET',
    'CAN_READ_CLIENT_MARKET_SHIFT',
    'CAN_EDIT_CLIENT_MARKET_SHIFT',
    'CAN_EDIT_CLIENT_MARKET_SHIFT_RATE',
    'CAN_READ_LOG_EMPLOYEE_LIST',
    'CAN_READ_SHIFT_TEMPLATE',
    'CAN_EDIT_SHIFT_TEMPLATE',
    'CAN_READ_LOG_MARKET_LIST',
    'CAN_EDIT_EMPLOYEE',
    'CAN_READ_EMPLOYEE',
    'CAN_EDIT_EMPLOYEE_SHIFT',
    'CAN_EDIT_CLIENT_MARKET_EMPLOYEE',
    'CAN_EDIT_CLIENT',
    'CAN_EDIT_CLIENT_MARKET',
    'CAN_READ_LOG_CLIENT_LIST',
    'CAN_READ_FREE_MARKET_SHIFT',
    'CAN_MARK_GOING_OUT_VIEWED',
    'CAN_EDIT_SHIFT_AFTER_SKIP',
    'CAN_SET_FIRST_EMPLOYEE_SHIFT_PROCESSED',
    'CAN_EDIT_REACTIVATION_TASK',
    'CAN_EDIT_SHIFT_EMPLOYEE_CHECKUP',
    'CAN_READ_SHIFT_EMPLOYEE_CHECKUP',
    'CAN_READ_DATE_BRIEF',
    'CAN_EDIT_DATE_BRIEF_PENALTY',
    'CAN_READ_FINANCE_PAYMENT',
    'CAN_READ_EMPLOYEE_FINANCE',
    'CAN_READ_CLIENT_FINANCE',
    'CAN_EXPORT_CLIENT_FINANCE',
    'CAN_READ_FINANCE_PAYOUT',
    'CAN_READ_MOBILE_FINANCE_PERIOD',
    'CAN_READ_MOBILE_TEST_FREE_SHIFTS',
    'CAN_EDIT_YELLOW_CARD',
    'CAN_READ_WHOLE_CLIENTS_STATISTIC',
    'CAN_READ_EMPLOYEE_STATISTICS',
    'CAN_EDIT_COMPETENCE',
    'CAN_READ_COMPETENCE',
    'CAN_EDIT_REGION',
    'CAN_READ_REGION',
    'CAN_EDIT_SECURITY_GROUP',
    'CAN_EDIT_USER',
    'CAN_EDIT_PARTNER_USER',
    'CAN_READ_SPLIT_STATISTICS',
    'CAN_EDIT_NOTIFICATION_PARAMETER_INFO',
    'CAN_READ_NOTIFICATION_PARAMETER_INFO',
    'CAN_EDIT_TRAINING_CENTER',
    'CAN_READ_TRAINING_CENTER',
    'CAN_EDIT_MAILING',
    'CAN_READ_MAILING',
    'CAN_PREPARE_MAILING',
    'CAN_EDIT_BONUS',
    'CAN_IMPORT_BULK_EMPLOYEE',
    'CAN_EXPORT_FINANCE_PAYOUT',
    'CAN_PAYOUT_FINANCE_PAYOUT',
    'CAN_EDIT_SPLIT',
    'CAN_EDIT_FINANCE_PAYOUT',
    'CAN_READ_LOG_MARKET_SHIFT_LIST',
    'CAN_EDIT_HRM_SETTINGS',
    'CAN_READ_HRM_SETTINGS',
    'CAN_MANAGE_CHECKIN',
    'CAN_READ_PARTNER',
    'CAN_EDIT_PARTNER',
    'CAN_READ_LOG_PARTNER_LIST',
    'CAN_DOWNLOAD_FINANCE_PAYOUT',
    'CAN_EDIT_CITIZENSHIP',
    'CAN_EDIT_PLANNED',
    'CAN_READ_CLIENT_MARKET_SUBSCRIPTION',
    'CAN_EDIT_CLIENT_MARKET_SUBSCRIPTION',
    'CAN_READ_CLIENT_MARKET_PHONES',
    'CAN_READ_EMPLOYEE_PHONE',
    'CAN_READ_CLIENT_PHONE',
    'CAN_EDIT_QUIZZES',
    'CAN_SEND_INVITE',
    'CAN_EDIT_POSITION',
    'CAN_EDIT_PAYMENT_DIVIDED',
    'CAN_READ_LABOR_RELATIONS',
    'CAN_EDIT_LABOR_RELATIONS',
    'CAN_READ_WORK_MONTH',
    'CAN_EDIT_WORK_MONTH',
    'CAN_READ_ROCKET_WORK_BALANCE',
    'CAN_READ_UNIT_TYPE',
  ];

  constructor(module: GroupEntityModule) {
    super(module);

    this.model = {
      id: '',
      name: '',
      isSupervisor: false,
      permissions: [],
    };
  }

  @Mutation
  SET_IS_EDIT(bool: boolean) {
    this.isEdit = bool;
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string }) {
    this.model[params.key] = params.value;
  }

  @Mutation
  SET_TITLE_EDIT(title: string) {
    this.titleEdit = title;
  }

  @Mutation
  SET_SINGLE_PERMISSION_LIST(list: string[]) {
    this.singlePermissionsList = list;
  }

  @Mutation
  SET_GROUPED_PERMISSIONS_LIST(list: Record<string, string[]>) {
    this.groupedPermissionsList = list;
  }

  @Mutation
  SET_SECURITY_GROUPS_LIST(list: SelectList) {
    this.securityGroupsList = list;
  }

  @Action({ rawError: true })
  async initAdd() {
    this.context.commit('SET_IS_EDIT', false);
    this.resetModel();
    await this.initSecurityGroupsSelect();
    await this.getPermissionsList();
  }

  @Action({ rawError: true })
  async initSecurityGroupsSelect() {
    const result = await getSecurityGroupsListAll();
    this.context.commit(
      'SET_SECURITY_GROUPS_LIST',
      result.map((item: { id: string; name: string }) => ({
        id: item.id,
        name: item.name,
        value: item.name,
      }))
    );
  }

  @Action({ rawError: true })
  async initEdit(id: string) {
    this.context.commit('SET_IS_EDIT', true);
    this.resetModel();
    await this.getPermissionsList();
    await this.getInfoById(id);
  }

  @Action({ rawError: true })
  async getInfoById(id: string) {
    try {
      const result = await readSecurityGroup(id);

      this.setInfo(result.securityGroup);
      this.setGroupPermissions(result.securityGroup);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async copySecurityGroupPermissions(id: string) {
    try {
      const result = await readSecurityGroup(id);

      this.setGroupPermissions(result.securityGroup);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getPermissionsList() {
    const tmpList: Record<string, { type: string; humanType: string; subject: string }[]> = {};
    const singlePermissionsList: string[] = [];
    const groupedPermissionsList: Record<string, string[]> = {};

    const result = await getPermissionsList();

    result.map((item: { type: string; humanType: string }) => {
      this.permissionsList[item.type] = {
        title: item.humanType,
        value: false,
      };

      if (!this.usedPermissions.includes(item.type)) {
        const matched = item.type.match(this.explodePermission);
        if (matched) {
          if (matched[1] === 'DELETE' || matched[1] === 'EDIT' || matched[1] === 'READ') {
            if (typeof tmpList[matched[2]] === 'undefined') {
              tmpList[matched[2]] = [];
            }

            tmpList[matched[2]].push({
              ...item,
              subject: matched[2],
            });
          } else {
            singlePermissionsList.push(item.type);
          }
        }
      }
    });

    Object.values(tmpList).map((item: { type: string; humanType: string; subject: string }[]) => {
      if (item.length > 1) {
        groupedPermissionsList[item[0].subject] = item.map((i) => i.type);
      } else {
        singlePermissionsList.push(item[0].type);
      }
    });

    this.context.commit('SET_SINGLE_PERMISSION_LIST', singlePermissionsList);
    this.context.commit('SET_GROUPED_PERMISSIONS_LIST', groupedPermissionsList);
  }

  @Action({ rawError: true })
  setInfo(info: GroupEntityInterface) {
    GroupsModule.updateTitleEdit(`${info.name}`);

    this.context.commit('SET_MODEL_VALUE', { key: 'id', value: info.id });
    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: info.name });
    this.context.commit('SET_MODEL_VALUE', { key: 'isSupervisor', value: info.isSupervisor });
  }

  @Action({ rawError: true })
  setGroupPermissions(info: GroupEntityInterface) {
    this.context.commit('SET_MODEL_VALUE', { key: 'permissions', value: info.permissions });

    Object.values(this.permissionsList).map((item) => {
      item.value = false;
    });

    info.permissions.map((permission: string) => {
      if (this.permissionsList[permission]) {
        this.permissionsList[permission].value = true;
      }
    });
  }

  @Action({ rawError: true })
  clearGroupPermissions() {
    this.context.commit('SET_MODEL_VALUE', { key: 'permissions', value: [] });

    Object.values(this.permissionsList).map((item) => {
      item.value = false;
    });
  }

  @Action({ rawError: true })
  async addNew(params: GroupEntityInterface) {
    try {
      const result = await createSecurityGroup({ name: params.name, isSupervisor: params.isSupervisor });
      if (result.message) {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });

        return false;
      }

      const permissionsFormData: Record<string, string> = {};
      if (params.permissions) {
        params.permissions.forEach((permission: string, index: number) => {
          permissionsFormData[`permissions[${index}]`] = permission;
        });
      }
      const resultPermissions = await setPermissions(result.securityGroup.id, permissionsFormData);

      if (!resultPermissions.message) {
        ResponseHandlerModule.showNotify({ message: 'Группа создана', type: 'ok' });

        return true;
      } else {
        ResponseHandlerModule.showNotify({ message: resultPermissions.message, type: 'fail' });
      }

      return false;
    } catch (error) {
      const errorData = error.response.data;
      if (errorData.type === 'validation_error') {
        this.showUniqueError(error.response.data);
      }

      ResponseHandlerModule.showNotify({ message: errorData.message, type: 'fail' });

      return false;
    }
  }

  @Action({ rawError: true })
  async saveUpdate(params: { id: string; data: GroupEntityInterface }) {
    try {
      const result = await updateSecurityGroup(params.id, {
        name: params.data.name,
        isSupervisor: params.data.isSupervisor,
      });

      const permissionsFormData: Record<string, string> = {};
      if (params.data.permissions) {
        params.data.permissions.forEach((permission: string, index: number) => {
          permissionsFormData[`permissions[${index}]`] = permission;
        });
      }
      const resultPermissions = await setPermissions(params.id, permissionsFormData);

      if (!result.message) {
        if (!resultPermissions.message) {
          ResponseHandlerModule.showNotify({ message: strings.SUCCESS_UPDATE, type: 'ok' });

          return true;
        } else {
          ResponseHandlerModule.showNotify({ message: resultPermissions.message, type: 'fail' });
        }
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }

      return false;
    } catch (error) {
      const errorData = error.response.data;
      if (errorData.type === 'validation_error') {
        this.showUniqueError(error.response.data);
      }

      ResponseHandlerModule.showNotify({ message: errorData.message, type: 'fail' });

      return false;
    }
  }

  @Action({ rawError: true })
  resetModel() {
    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isSupervisor', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'permissions', value: [] });
  }

  @Action({ rawError: true })
  showUniqueError(errorData: { type: string; errors: { fields: Record<string, string> } }) {
    const fields = errorData.errors.fields;
    ResponseHandlerModule.hideNotify();
    ResponseHandlerModule.showNotify({ message: Object.values(fields)[0] as string, type: 'fail' });
  }
}

export default getModule(GroupEntityModule);
