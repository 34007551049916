
import { Prop, Component } from '@/lib/decorator';
import FormEditor from '@/lib/FormEditor';

import FormInput from '@/components/form/Input/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';

import { GuiFormCheckbox } from '@library/gigant_ui';

import { strings } from '@/lib/stringConst';
import GroupEntityModule, { GroupEntityInterface } from '@/store/settings/groups/entity';
import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { SelectList } from '@/lib/formFactory/select.interface';
import { GroupModel } from '@/interfaces/models/group.interface';

@Component({
  emits: ['returnBefore'],
  components: {
    AppCol,
    AppRow,
    AppFieldset,
    FormInput,
    FormSelect,
    FormButton,
    FormSimpleSwitch,
    GuiFormCheckbox,
  },
})
export default class GroupsEntityForm extends FormEditor {
  @Prop() typeAction!: string;

  securityGroupToCopy = { id: '', value: '' };
  name = this.model.name ?? '';
  isSupervisor = this.model.isSupervisor ?? false;

  rules = {
    name: (value: string): string | boolean => {
      if (!value.length) return true;

      return /^[A-Za-zА-Яа-яЁёЪъ\- ]+$/.test(value) || 'Введены некорректные символы';
    },
  };

  get isEdit(): boolean {
    return GroupEntityModule.isEdit;
  }

  get securityGroupsList(): SelectList {
    return GroupEntityModule.securityGroupsList;
  }

  get permissionsList(): Record<string, { value: boolean; title: string }> {
    return GroupEntityModule.permissionsList;
  }

  get singlePermissionsList(): string[] {
    return GroupEntityModule.singlePermissionsList;
  }

  get groupedPermissionsList(): Record<string, string[]> {
    return GroupEntityModule.groupedPermissionsList;
  }

  get text(): Record<string, string> {
    return strings;
  }

  get model(): GroupModel {
    return GroupEntityModule.model;
  }

  get requestData(): GroupEntityInterface {
    return {
      name: this.name,
      isSupervisor: this.isSupervisor ? '1' : '0',
      permissions: this.preparePermissionsList(),
    };
  }

  returnBefore(): void {
    this.$emit('returnBefore');
  }

  handleSecurityGroupSelect(selectedValue: { id: string; value: string }): void {
    if (selectedValue.id) {
      GroupEntityModule.copySecurityGroupPermissions(selectedValue.id);
    } else {
      GroupEntityModule.clearGroupPermissions();
    }
  }

  modelValueForSelect(permissionsList: string[]): Record<string, string> {
    for (const permission of permissionsList) {
      if (this.permissionsList[permission] && this.permissionsList[permission].value) {
        return {
          id: permission,
          value: this.permissionsList[permission].title,
        };
      }
    }

    return {
      id: '',
      value: 'Запрещено',
    };
  }

  itemsForSelect(permissionsList: string[]): Record<string, string>[] {
    return [
      {
        id: '',
        value: 'Запрещено',
      },
      ...permissionsList.map((permission: string) => ({
        id: permission,
        value: this.permissionsList[permission] ? this.permissionsList[permission].title : '',
      })),
    ];
  }

  handleSelectUpdate(selectedValue: { id: string; value: string }, permissionsList: string[]): void {
    for (const permission of permissionsList) {
      if (this.permissionsList[permission]) {
        this.permissionsList[permission].value = selectedValue.id === permission;
      }
    }
  }

  preparePermissionsList(): string[] {
    const permissions = [];
    for (const permission in this.permissionsList) {
      if (this.permissionsList[permission].value) {
        permissions.push(permission);
      }
    }

    return permissions;
  }

  async add(): Promise<void> {
    try {
      if (await GroupEntityModule.addNew(this.requestData)) {
        this.returnBefore();
      }
    } catch (e) {
      ResponseHandlerModule.showNotify({ message: 'Не удалось создать сущность "Группа"', type: 'fail' });
    }
  }

  async edit(): Promise<void> {
    try {
      if (
        await GroupEntityModule.saveUpdate({
          id: this.$route.params.id as string,
          data: this.requestData,
        })
      ) {
        this.returnBefore();
      }
    } catch (e) {
      ResponseHandlerModule.showNotify({ message: 'Не удалось сохранить сущность "Группа"', type: 'fail' });
    }
  }
}
